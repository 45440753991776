
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import AtlasPlatformActivitiesTimelineItem from "./AtlasPlatformActivitiesTimelineItem.vue";
interface IAtlasPlatformActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "AtlasPlatformActivitiesView",
  components: { AtlasPlatformActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): IAtlasPlatformActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          ADD_ATLAS_PLATFORM_FAILURE,
          ADD_ATLAS_PLATFORM_SUCCESS,
          UPDATE_ATLAS_PLATFORM_FAILURE,
          UPDATE_ATLAS_PLATFORM_SUCCESS,
          DELETE_ATLAS_PLATFORM_SUCCESS,
          DELETE_ATLAS_PLATFORM_FAILURE,
          BULK_DELETE_ATLAS_PLATFORM_FAILURE,
          BULK_DELETE_ATLAS_PLATFORM_SUCCESS
          `.replace(/\s/g, ""),
        activityType: "AtlasPlatformActivity"
      };
    }
  }
});
