<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div v-html="formattedActivityText" class=""></div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
export default {
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    AtlasPlatform: {
      type: Object,
      required: false
    }
  },
  methods: {
    async generateReadableUpdateAtlasPlatform() {
      let contentHtml = ``;
      for (let update of this.updates) {
        contentHtml = this.generateRegularActivityString(contentHtml, update);
      }
      this.setReadableUpdateProps(contentHtml);
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "ADD_ATLAS_PLATFORM_SUCCESS":
            this.generateReadableGeneric({
              content: "added a new atlas platform.",
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_ATLAS_PLATFORM_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add a new atlas platform.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_ATLAS_PLATFORM_SUCCESS":
            await this.generateReadableUpdateAtlasPlatform();
            break;
          case "UPDATE_ATLAS_PLATFORM_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to update atlas platform.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_ATLAS_PLATFORM_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted an atlas platform.",
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_ATLAS_PLATFORM_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete atlas platform.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "BULK_DELETE_ATLAS_PLATFORM_SUCCESS":
            this.generateReadableGeneric({
              content: this.bulkDeletionWording,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "BULK_DELETE_ATLAS_PLATFORM_FAILURE":
            this.generateReadableFailGeneric({
              content: this.bulkDeletionWording,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  },
  computed: {
    bulkDeletionWording() {
      return this?.activity?.data?.platformIds?.length > 1
        ? `deleted ${this?.activity?.data?.platformIds?.length} atlas platforms.`
        : `deleted 1 atlas platform.`;
    }
  }
};
</script>

<style></style>
